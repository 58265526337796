<!--
File: GenerateTreatment.vue
Description: shows admin panel for preparatory step befor the Title list generations - creating
             homogeneous sections, generating worklists, etc..
-->
<template>
  <VueTabs>
    <v-tab id="tab0" :title="$t('tabs.homo_sections')">
      <HomoSectionsList />
    </v-tab>

    <v-tab id="tab1" :title="$t('tabs.treatment_costs')">
      <TreatmentsCosts />
    </v-tab>
  </VueTabs>
</template>
<script>
  import { VueTabs, VTab } from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.css'

  import HomoSectionsList from './HomoSectionsList.vue'
  import TreatmentsCosts from './TreatmentsCosts.vue'

  export default {
    name: 'generate-treatment-form',
    data() {
      return {
      }
    },

    components: {
      VueTabs,
      VTab,
      HomoSectionsList,
      TreatmentsCosts,
    },

    mounted() {
    },

    methods: {
    },

    computed: {
    },
  }
</script>
<style lang="scss" scoped>
.vue-tabs {
  height: 100%;
}

.vue-tabs .tab-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.vue-tabs .tab-content > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.vue-tabs .nav-tabs>li.active>a,
.vue-tabs .nav-tabs>li.active>a:hover,
.vue-tabs .nav-tabs>li.active>a:focus {
  background-color: #4caf50;
}

.homo-sections-list, .treatments-costs {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
