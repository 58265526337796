<!--
File: HomoSectionInputForm.vue
Description: form for entering parameters for generatin homogeneous sections list.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-40">
            <BaseDropdown :label="$t('label.from_year')" v-model="selectedYearFrom" :items="years" :displayField="'year'"
              :valueField="'year'" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-40">
            <BaseDropdown :label="$t('label.to_year')" v-model="selectedYearTo" :items="years" :displayField="'year'"
              :valueField="'year'" />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-40">
            <md-field :class="[{ 'md-valid': !errors.has('ciThreshold') }, { 'md-error': errors.has('ciThreshold') }]">
              <label for="ciThreshold">{{ $t('stdCols.range') }}</label>
              <md-input id="ciThreshold" v-model="ciThreshold" data-vv-name="ciThreshold" type="number" min=1 max=50
                required v-validate="modelValidations.ciThreshold" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-40">
            <md-field :class="[{ 'md-valid': !errors.has('minHsLength') }, { 'md-error': errors.has('minHsLength') }]">
              <label for="min_hs_length">{{ $t('label.min_hs_length') }}</label>
              <md-input id="min_hs_length" v-model="minHsLength" data-vv-name="minHsLength" type="number" min=100 max=5000
                step=100 required v-validate="modelValidations.minHsLength" />
            </md-field>
          </div>
        </div>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.generate') }}
            </md-button>
            <md-button class="md-primary" @click.stop.prevent="onModalClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import { mapState } from 'vuex'
  import BaseDropdown from '@/pages/Dropdowns/BaseDropdown.vue'
  import messages from '@/mixins/messagesMixin'
  import { onModalClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'homosection-parameters-input-form',
    mixins: [messages],
    data() {
      return {
        selectedYearFrom: null,
        selectedYearTo: null,
        years: [],
        minHsLength: 100,
        ciThreshold: 10,

        modelValidations: {
          minHsLength: { required: true, numeric: true, min_value: 100, max_value: 5000 },
          ciThreshold: { required: true, numeric: true, min_value: 1, max_value: 50 },
        },
      }
    },

    components: {
      BaseDropdown
    },

    mounted() {
      const thisYear = new Date().getFullYear()
      const yearsBack = 2 // used to set to 6 during HDM finishing in 2024
      this.selectedYearTo = thisYear
      this.selectedYearFrom = thisYear - yearsBack
      this.years = Array.from({ length: yearsBack + 1 }, (_, index) => ({ year: thisYear - (yearsBack - index) }));
    },

    methods: {
      onModalClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        // Check whether there is already generated list with same parameters 
        const exists = this.hsList.find(el =>
          el.input_year_from == this.selectedYearFrom &&
          el.input_year_to == this.selectedYearTo &&
          el.input_min_hs_length == this.minHsLength &&
          el.input_condition_index_threshold == this.ciThreshold
        )
        if (exists) {
          this.generatedListExistsMessage()
          return
        }

        const params = {
          year_from: this.selectedYearFrom,
          year_to: this.selectedYearTo,
          min_hs_length: this.minHsLength,
          condition_index_threshold: this.ciThreshold
        }
        this.$emit('input', params)
      }
    },

    computed: {
      ...mapState({
        hsList: (state) => state.HomoSections.list,
      }),

      saveBtnDisabled() {
        return this.errors.items.length > 0
      },
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
